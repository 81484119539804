import React from 'react';
import Home from './pages/Home'
import { ChatContextProvider } from './context/chatContext'
import { useAuthState } from 'react-firebase-hooks/auth'

import { auth } from './firebase'

const App = () => {

return (
<ChatContextProvider>
<div>
<Home />
</div>
</ChatContextProvider >
)
}

export default App